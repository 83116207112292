import { createFeature, createReducer, on } from '@ngrx/store';
import { addDays, format } from 'date-fns';
import { environment } from '../../../../environments/environment';
import { toggleValueInArrayWithLimit } from '../../../core/helpers/helpers';
import { eventFormSteps } from '../models/models';
import {
  UploadEventContainerActions,
  UploadEventEffectsActions,
} from './index';
import { UploadEventState } from './upload-event.state';

export const initialState: Readonly<UploadEventState> = {
  currentStep: 'eventInformation',
  eventInformation: {
    name: '',
    startDate: format(addDays(new Date(), 1), 'yyyy-MM-dd'),
    endDate: format(addDays(new Date(), 2), 'yyyy-MM-dd'),
    startTime: '',
    endTime: '',
    fromPrice: '',
    pressText: '',
    ticketLink: '',
    ageRestrictionWomen: '',
    ageRestrictionMen: '',
    fyraTicketLink: '',
    internalLabel: -1,
    location: 0,
    locationName: '',
    locationStreet: '',
    locationPlz: '',
    locationProvince: '',
    eventId: undefined,
    genres: [],
    tags: [],
    flyer: null,
  },
  products: {
    flyer: {
      id: environment.production
        ? 'price_1McbgAGa89ImEHjikiSQIN0p'
        : 'price_1OyLF6Ga89ImEHjiInJGgCbh',
      key: 'flyer',
      name: 'Flyer',
      description: '',
      price: 0,
      selected: false,
      expanded: false,
      disabled: false,
      icon: 'https://a.storyblok.com/f/158785/687x687/b8698535ac/image.png?cv=1682497831710',
    },
    mark: {
      id: environment.production
        ? 'price_1NudBZGa89ImEHjiiPqKEFTY'
        : 'price_1OEzgNGa89ImEHjiv0RmhjL1',
      key: 'mark',
      name: 'Event Markieren',
      description:
        'Mit dem Begriff PIN ist gemeint, dass bestehende Events in einer jeweiligen Filterung zuoberst gepinnt und markiert werden.',
      price: 29,
      selected: false,
      expanded: false,
      disabled: false,
      icon: 'https://a.storyblok.com/f/158785/694x694/65595aff56/highlight.png?cv=1682497831603',
    },
    pin: {
      id: environment.production
        ? 'price_1MbswvGa89ImEHjig0bPLui7'
        : 'price_1OEzeoGa89ImEHjitYuN9MT9',
      key: 'pin',
      name: 'Event PIN',
      description:
        'Mit dem Begriff PIN ist gemeint, dass bestehende Events in einer jeweiligen Filterung zuoberst gepinnt und markiert werden.',
      price: 49,
      selected: false,
      expanded: false,
      disabled: false,
      icon: 'https://a.storyblok.com/f/158785/606x692/ee70b86570/pin.png?cv=1682497831713',
    },
  },
  eventFormSteps,
  selectedGenres: [],
  selectedTags: [],
  originalImage: '',
};

export const uploadEventReducers = createReducer(
  initialState,
  on(
    UploadEventContainerActions.eventInformationFormChanged,
    (state, { eventInformation }) => ({
      ...state,
      eventInformation: {
        ...state.eventInformation,
        name: eventInformation.name,
        startDate: eventInformation.startDate,
        endDate: eventInformation.endDate,
        startTime: eventInformation.startTime,
        endTime: eventInformation.endTime,
        internalLabel: eventInformation.internalLabel,
        location: eventInformation.location,
        locationName: eventInformation.locationName,
        locationStreet: eventInformation.locationStreet,
        locationPlz: eventInformation.locationPlz,
        locationProvince: eventInformation.locationProvince,
        ageRestrictionWomen: eventInformation.ageRestrictionWomen,
        ageRestrictionMen: eventInformation.ageRestrictionMen,
        pressText: eventInformation.pressText,
      },
    }),
  ),
  on(UploadEventContainerActions.flyerCropped, (state, { flyer }) => ({
    ...state,
    eventInformation: {
      ...state.eventInformation,
      flyer,
    },
  })),
  on(UploadEventContainerActions.toggleGenreClicked, (state, { genreId }) => ({
    ...state,
    selectedGenres: toggleValueInArrayWithLimit(
      state.selectedGenres,
      genreId,
      3,
    ),
  })),
  on(UploadEventContainerActions.toggleTagClicked, (state, { tagId }) => ({
    ...state,
    selectedTags: toggleValueInArrayWithLimit(state.selectedTags, tagId, 3),
  })),
  on(
    UploadEventContainerActions.productSelectionToggled,
    (state, { product }) => ({
      ...state,
      products: {
        ...state.products,
        [product.key]: {
          ...state.products[product.key],
          selected: !state.products[product.key]!.selected,
          expanded:
            state.products[product.key]!.expanded ||
            !state.products[product.key]!.selected,
        },
      },
    }),
  ),
  on(
    UploadEventContainerActions.eventTicketingFormChanged,
    (state, { eventTicketing }) => ({
      ...state,
      eventInformation: {
        ...state.eventInformation,
        fromPrice: eventTicketing.fromPrice,
        ticketLink: eventTicketing.ticketLink,
        fyraTicketLink: eventTicketing.fyraTicketLink,
      },
    }),
  ),
  on(UploadEventContainerActions.flyerUploaded, (state, { originalImage }) => ({
    ...state,
    originalImage,
  })),
  on(
    UploadEventEffectsActions.uploadEventFormInvalid,
    (state, { invalidSteps }) => ({
      ...state,
      eventFormSteps: Object.fromEntries(
        Object.entries(state.eventFormSteps).map(([key, value]) => [
          key,
          {
            ...value,
            state: (key === 'overview'
              ? 'unvalidated'
              : invalidSteps.includes(key)
                ? 'invalid'
                : 'valid') as 'unvalidated' | 'valid' | 'invalid',
          },
        ]),
      ),
    }),
  ),
  on(UploadEventContainerActions.setStep, (state, { stepId }) => {
    const updatedSteps = { ...state.eventFormSteps };

    updatedSteps[stepId] = { ...updatedSteps[stepId], active: true };

    const previouslyActiveStep = Object.keys(updatedSteps).find(
      (key) =>
        updatedSteps[key].active === true &&
        updatedSteps[key].stepId !== stepId,
    );
    if (previouslyActiveStep) {
      updatedSteps[previouslyActiveStep] = {
        ...updatedSteps[previouslyActiveStep],
        active: false,
      };
    }

    return { ...state, eventFormSteps: updatedSteps };
  }),
  on(
    UploadEventEffectsActions.stepOneFormValid,
    UploadEventEffectsActions.stepTwoFormValid,
    UploadEventEffectsActions.stepThreeFormValid,
    UploadEventEffectsActions.stepFourFormValid,
    (state) => {
      const updatedSteps = { ...state.eventFormSteps };
      const keys = Object.keys(updatedSteps);
      const activeStep = Object.keys(updatedSteps).find(
        (key) => updatedSteps[key].active,
      );
      const currentItemIndex = keys.indexOf(activeStep!);

      if (currentItemIndex < 4) {
        const previousKey = keys[currentItemIndex + 1];
        updatedSteps[previousKey] = {
          ...updatedSteps[previousKey],
          active: true,
        };
      }

      updatedSteps[activeStep!] = {
        ...updatedSteps[activeStep!],
        active: false,
        state: 'valid',
      };
      return { ...state, eventFormSteps: updatedSteps };
    },
  ),
  on(UploadEventContainerActions.lastStepClicked, (state) => {
    const updatedSteps = { ...state.eventFormSteps };
    const keys = Object.keys(updatedSteps);
    const activeStep = Object.keys(updatedSteps).find(
      (key) => updatedSteps[key].active,
    );
    const currentItemIndex = keys.indexOf(activeStep!);

    if (currentItemIndex > 0) {
      const previousKey = keys[currentItemIndex - 1];
      updatedSteps[previousKey] = {
        ...updatedSteps[previousKey],
        active: true,
      };
    }

    updatedSteps[activeStep!] = { ...updatedSteps[activeStep!], active: false };
    return { ...state, eventFormSteps: updatedSteps };
  }),
  on(
    UploadEventEffectsActions.stepOneFormInvalid,
    UploadEventEffectsActions.stepTwoFormInvalid,
    UploadEventEffectsActions.stepThreeFormInvalid,
    UploadEventEffectsActions.stepFourFormInvalid,
    (state) => {
      const updatedSteps = { ...state.eventFormSteps };
      const activeStep = Object.keys(updatedSteps).find(
        (key) => updatedSteps[key].active,
      );

      updatedSteps[activeStep!] = {
        ...updatedSteps[activeStep!],
        state: 'invalid',
      };
      return { ...state, eventFormSteps: updatedSteps };
    },
  ),
  on(UploadEventEffectsActions.uploadEventSuccessful, (state) => ({
    ...state,
    currentStep: 'eventInformation',
    eventInformation: {
      name: '',
      startDate: format(addDays(new Date(), 1), 'yyyy-MM-dd'),
      endDate: format(addDays(new Date(), 2), 'yyyy-MM-dd'),
      time: '',
      startTime: '',
      endTime: '',
      fromPrice: '',
      internalLabel: -1,
      genre: 0,
      pressText: '',
      ticketLink: '',
      ageRestrictionWomen: '',
      ageRestrictionMen: '',
      fyraTicketLink: '',
      location: null,
      locationName: '',
      locationStreet: '',
      locationPlz: '',
      locationProvince: '',
      genres: [],
      tags: [],
      flyer: null,
    },
    products: {
      flyer: {
        ...state.products['flyer'],
      },
      mark: {
        ...state.products['mark'],
        selected: false,
        expanded: false,
      },
      pin: {
        ...state.products['pin'],
        selected: false,
        expanded: false,
      },
    },
    eventFormSteps,
    selectedGenres: [],
    selectedTags: [],
    originalImage: '',
  })),
);

export const uploadEventFeature = createFeature({
  name: 'uploadEvent',
  reducer: uploadEventReducers,
});
