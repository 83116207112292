import * as UploadEventContainerActions from './actions/upload-event-container.actions';
import * as UploadEventEffectsActions from './actions/upload-event-effects.actions';
import * as UploadSuccessfulGuardActions from './actions/upload-successful-guard.actions';
import * as UploadCancelledGuardActions from './actions/upload-cancelled-guard.actions';
import {uploadEventFeature} from './upload-event.reducers';

export * as uploadEventState from './upload-event.state';

const {reducer, name, ...uploadEventSelectors} = uploadEventFeature;

export {
  reducer as uploadEventReducer,
  name as uploadEventName,
  UploadEventContainerActions,
  UploadEventEffectsActions,
  uploadEventSelectors,
  UploadSuccessfulGuardActions,
  UploadCancelledGuardActions
};
