import { createAction, props } from '@ngrx/store';
import { Product } from '../../../../core/models/app.models';
import {
  EventInformationFormData,
  EventTicketingFormData,
} from '../../../../core/models/event-form.models';

export const eventInformationFormChanged = createAction(
  '[Upload Event Container] Event Information Form Changed',
  props<{
    eventInformation: EventInformationFormData;
  }>(),
);
export const eventTicketingFormChanged = createAction(
  '[Upload Event Container] Event Ticketing Form Changed',
  props<{
    eventTicketing: EventTicketingFormData;
  }>(),
);
export const nextButtonStepOneClicked = createAction(
  '[Upload Event Container] Next Button Step One Clicked',
);
export const nextButtonStepTwoClicked = createAction(
  '[Upload Event Container] Next Button Step Two Clicked',
);
export const uploadEventContainerCalled = createAction(
  '[Upload Event Container] Upload Event Container Called',
);
export const nextButtonStepThreeClicked = createAction(
  '[Upload Event Container] Next Button Step Three Clicked',
);
export const nextButtonStepFourClicked = createAction(
  '[Upload Event Container] Next Button Step Four Clicked',
);
export const uploadClicked = createAction(
  '[Upload Event Container] Upload Clicked',
);
export const lastStepClicked = createAction(
  '[Upload Event Container] Last Step Clicked',
);
export const productSelectionToggled = createAction(
  '[Upload Event Container] Product Selection Toggled',
  props<{
    product: Product;
  }>(),
);
export const productExpansionToggled = createAction(
  '[Upload Event Container] Product Expansion Toggled',
  props<{
    product: Product;
  }>(),
);
export const setStep = createAction(
  '[Upload Event Container] Set Step',
  props<{ stepId: string }>(),
);
export const toggleGenreClicked = createAction(
  '[Upload Event Container] Toggle Genre Clicked',
  props<{
    genreId: number;
  }>(),
);
export const toggleTagClicked = createAction(
  '[Upload Event Container] Toggle Tag Clicked',
  props<{ tagId: number }>(),
);
export const flyerUploaded = createAction(
  '[Upload Event Container] Flyer Uploaded',
  props<{
    originalImage: string;
  }>(),
);
export const flyerCropped = createAction(
  '[Upload Event Container] Flyer Cropped',
  props<{ flyer: string }>(),
);
