import { createAction, props } from '@ngrx/store';

export const stepOneFormValid = createAction(
  '[Upload Event Effects] Step One Form Valid',
);
export const stepTwoFormValid = createAction(
  '[Upload Event Effects] Step Two Form Valid',
);
export const stepThreeFormValid = createAction(
  '[Upload Event Effects] Step Three Form Valid',
);
export const stepFourFormValid = createAction(
  '[Upload Event Effects] Step Four Form Valid',
);
export const stepOneFormInvalid = createAction(
  '[Upload Event Effects] Step One Form Invalid',
);
export const stepTwoFormInvalid = createAction(
  '[Upload Event Effects] Step Two Form Invalid',
);
export const stepThreeFormInvalid = createAction(
  '[Upload Event Effects] Step Three Form Invalid',
);
export const stepFourFormInvalid = createAction(
  '[Upload Event Effects] Step Four Form Invalid',
);
export const uploadEventSuccessful = createAction(
  '[Upload Event Effects] Upload Event Successful',
);
export const uploadEventFromStorageSuccessful = createAction(
  '[Upload Event Effects] Upload Event From Storage Successful',
);
export const uploadEventUnsuccessful = createAction(
  '[Upload Event Effects] Upload Event Unsuccessful',
);
export const uploadEventFromStorageUnsuccessful = createAction(
  '[Upload Event Effects] Upload Event From Storage Unsuccessful',
);
export const uploadEventStarted = createAction(
  '[Upload Event Effects] Upload Event Started',
);
export const uploadEventPostponed = createAction(
  '[Upload Event Effects] Upload Event Postponed',
);
export const uploadEventSuccessfulWithPayment = createAction(
  '[Upload Event Effects] Upload Event Successful With Payment',
);
export const paymentStartedSuccessful = createAction(
  '[Upload Event Effects] Payment Started Successful',
  props<{
    paymentId: string;
  }>(),
);
export const uploadEventFormInvalid = createAction(
  '[Upload Event Effects] Upload Event Form Invalid',
  props<{
    invalidSteps: string[];
  }>(),
);
export const paymentStartedUnsuccessful = createAction(
  '[Upload Event Effects] Payment Started Unsuccessful',
);
export const formStepsValid = createAction(
  '[Upload Event Container] Form Steps Valid',
);
